.rc-tooltip {
  z-index: 1070;
  visibility: visible;
  opacity: .9;
  font-size: 12px;
  line-height: 1.5;
  display: block;
  position: absolute;
}

.rc-tooltip-hidden {
  display: none;
}

.rc-tooltip-placement-top, .rc-tooltip-placement-topLeft, .rc-tooltip-placement-topRight {
  padding: 5px 0 9px;
}

.rc-tooltip-placement-right, .rc-tooltip-placement-rightTop, .rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}

.rc-tooltip-placement-bottom, .rc-tooltip-placement-bottomLeft, .rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px;
}

.rc-tooltip-placement-left, .rc-tooltip-placement-leftTop, .rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}

.rc-tooltip-inner {
  color: #fff;
  text-align: left;
  background-color: #373737;
  border-radius: 6px;
  min-height: 34px;
  padding: 8px 10px;
  text-decoration: none;
  box-shadow: 0 0 4px #0000002b;
}

.rc-tooltip-arrow {
  border-style: solid;
  border-color: #0000;
  width: 0;
  height: 0;
  position: absolute;
}

.rc-tooltip-placement-top .rc-tooltip-arrow, .rc-tooltip-placement-topLeft .rc-tooltip-arrow, .rc-tooltip-placement-topRight .rc-tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #373737;
  margin-left: -5px;
  bottom: 4px;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}

.rc-tooltip-placement-right .rc-tooltip-arrow, .rc-tooltip-placement-rightTop .rc-tooltip-arrow, .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-right-color: #373737;
  margin-top: -5px;
  left: 4px;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  margin-top: 0;
  top: 15%;
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-left .rc-tooltip-arrow, .rc-tooltip-placement-leftTop .rc-tooltip-arrow, .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-left-color: #373737;
  margin-top: -5px;
  right: 4px;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  margin-top: 0;
  top: 15%;
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow, .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow, .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #373737;
  margin-left: -5px;
  top: 4px;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

.rc-tooltip.rc-tooltip-zoom-enter, .rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}

.rc-tooltip-zoom-enter, .rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(.18, .89, .32, 1.28);
  animation-play-state: paused;
  animation-fill-mode: both;
}

.rc-tooltip-zoom-leave {
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(.6, -.3, .74, .05);
  animation-play-state: paused;
  animation-fill-mode: both;
}

.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active, .rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}

.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}

@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50%;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform-origin: 50%;
    transform: scale(1);
  }
}

@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50%;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform-origin: 50%;
    transform: scale(0);
  }
}

/*# sourceMappingURL=index.bfac6bd6.css.map */
